// Webpack Imports
import * as bootstrap from 'bootstrap';


(function () {
  'use strict';

  // Focus input if Searchform is empty
  [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
    el.addEventListener('submit', function (e) {
      var search = el.querySelector('input');
      if (search.value.length < 1) {
        e.preventDefault();
        search.focus();
      }
    });
  });

  // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      trigger: 'focus',
    });
  });

  // Initialize Tooltips: https://getbootstrap.com/docs/5.0/components/tooltips/
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  //Trigger mobile menu
  $('#menu-btn').on('click', function(){
    $('#menu-btn').toggleClass("active");
    $('#menu-links').toggleClass("active");
    $('#menu').toggleClass("open");
  });

  //Trigger mobile user
  $('#user-btn').on('click', function(){
    $('#user-btn').toggleClass("active");
    $('#user-menu').toggleClass("active");
  });

  //Trigger mobile search
  $('#search-btn').on('click', function(){
    $('#search-btn').toggleClass("active");
    $('#search-menu').toggleClass("active");
  });

  //Trigger mobile filters
  $('#filters-btn-phone__close').hide();
  $('#filters-btn-phone').on('click', function(){
    $('#filters-btn-phone').toggleClass("active");
    $('#filters-menu').toggleClass("active");
    $('#filters-btn-phone__close, #filters-btn-phone__filter').toggle();
  });

  //Trigger mobile cart
  $('#cart-btn-phone').on('click', function(){
    $('#cart-btn-phone').toggleClass("active");
    $('#cart-menu').toggleClass("active");
  });

  //Keep Dropdown open after click inside
  $('.dropdown-menu').click(function(e) {
    e.stopPropagation();
  });

  //Table accordion
  $('.btn-arrow').click(function() {
    $(this).parents('.collapse-row').toggleClass('active');
  })

  // A utiliser sur tous les form qui auront un bouton de reset.
  // A compléter avec tous les autres types de champs qui auront besoin d'etre ajouté si le type='reset' ne le gère pas nativement
  $('[type="reset"]').on('click', (e) => {
      window.location = window.location.origin + window.location.pathname;
  })

  const welcomeModal = document.getElementById('welcome-modal');
  if (welcomeModal) {
    const bootstrapModal = new bootstrap.Modal(welcomeModal);
    bootstrapModal.show();
  }

})();

const afterSaleForm = document.querySelector(".page-template-template-request-sav");
if(afterSaleForm){
  const afterSaleMain = document.querySelector("div[data-id=aftersale-main-data]");
  const form = document.querySelector('form.wpcf7-form');
  // On vérifie si afterSaleMain a été révélé par le champs conditionnel
  const observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName === 'class' && afterSaleMain.classList.contains('wpcf7cf-hidden')) {
        // On désactive le formulaire pour empêcher la soumission
        form.addEventListener('submit', preventFormSubmission);
      } else {
        // On Active le formulaire pour permettre la soumission
        form.removeEventListener('submit', preventFormSubmission);
      }
    });
  });
  const config = { attributes: true, attributeFilter: ['class'] };
  observer.observe(afterSaleMain, config);
  function preventFormSubmission(event) {
    event.preventDefault();
  }
}